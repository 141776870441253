.overall {
  padding-bottom: 50px;

}

.overallItem {
  padding-bottom: 24px;

  img {
    display: block;
    width: 130px;
    height: 130px;
    margin: 0 auto;
  }

  p {
    padding: 0 0 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    text-align: center;
  }

  &:last-child {
    padding-bottom: 0;
  }

}

.topHeader {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.headerWrapper {
  position: relative;
  padding: 0 32px 38px;

  .talentTag {
    width: 89px;
  }

  .loginBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    margin-top: 33px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    background: #FFD14C;
    border-radius: 52px;
  }
}

.talent {
  width: 100%;
  background-color: #fff;

  * {
    font-family: "Noto Sans JP", sans-serif !important;
  }

  img {
    object-fit: cover;
  }

  .talentHome {
    display: block;
    width: 100%;
    margin: 0 auto;
  }


  .preview {
    padding-top: 57px;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    color: #FFF;
    text-align: left;
  }

  .received {
    padding: 20px 0 57px;
    padding-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    text-align: left;
  }


  .w1200 {
    display: flex;
    align-items: center;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 39px;
    padding-bottom: 22px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #111;
  }


  .commonHeader {
    display: inline-block;
    margin: 0 auto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #0A0A0A;
    text-align: center;
  }

  .line {
    width: calc(100% - 10px);
    height: 12px;
    margin: 0 auto;
    background: linear-gradient(90deg, #088CFC 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  .kindWork {
    padding: 34px 0 40px;
    text-align: center;
    background: #F5F6F8;

    .wrapper {
      padding: 6px 24px 0;
      margin: 0 auto;
    }

  }

  .kindWorkItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px 0 14px 18px;
    margin-top: 12px;
    background: #FFF;
    border-radius: 22px;

    .aside {
      flex: 1;
      text-align: left;
    }

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      border-radius: 11px;
    }

    .tip {
      padding-bottom: 6px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #0A0A0A;
    }


  }

  .info {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #0A0A0A;
  }

  .header {
    display: inline-flex;
    align-items: center;
    color: #000;

    svg {
      margin-right: 5px;
    }
  }

  .features {
    padding: 39px 24px 40px;
    margin: 0 auto;
    text-align: center;
    background: #2378E5;

    .commonHeader {
      margin-bottom: 24px;
    }

    .header {
      color: #FFCA30;
    }


    .line {
      background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .list {
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: left;
    background: #FFF;
    border-radius: 44px;

    &:last-child {
      margin-bottom: 0;
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }


    h5 {
      padding: 12px 0 7px 23px;
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }

    .desc {
      padding-bottom: 24px;
    }

    .info {
      display: flex;
      padding-left: 23px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #000;

      i {
        position: relative;
        top: 8px;
        display: inline-block;
        flex-shrink: 0;
        width: 4px;
        height: 4px;
        margin-right: 12px;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }

  .login {
    padding: 39px 24px 56px;
    text-align: center;
    background: #F5F6F8;

    .header {
      svg {
        margin-right: 0;
      }
    }
  }

  .loginBox {
    width: 100%;
    margin: 36px auto 0;
    overflow: hidden;
    background: #fff;
    border-radius: 28px;

    h2 {
      padding: 28px 0 23px 20px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }
  }

  .imgBox {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  .talentLogin {
    width: 133px;
    height: 164px;
    margin-left: 20px;
  }

  .loginHome {
    width: 215px;
  }

  .appBox {
    padding: 24px 0 0;
    margin: 0 auto;
  }

  .talentApp {
    position: relative;
    flex-shrink: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    background: #2378E5;
    border-radius: 28px;

    p {
      padding: 28px 0 20px 32px;
      text-align: left;
    }

    img {
      position: relative;
      top: 6px;
      width: 264px;
    }
  }

  .position {
    margin: 24px auto 0;
    background: #FFF;
    border-radius: 43px;

    img {
      width: 100%;
    }

    p {
      padding: 0 32px 38px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }
  }

  .method {
    position: relative;
    width: 100%;
    padding: 34px 24px 55px;
    overflow: hidden;
    text-align: center;

    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 136px;
    }
  }

  .country {
    position: relative;
    z-index: 10;
    height: 350px;
    margin: 34px auto 16px;
    border-radius: 43px;

    .content {
      position: relative;
      z-index: 10;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    p {
      padding: 33px 0 0 32px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }

    img {
      position: absolute;
      top: -38px;
      right: -30px;
      z-index: -1;
      width: 116px;
    }
  }

  .userCenter {
    position: relative;
    z-index: 10;
    height: 326px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 43px;

    p {
      padding: 33px 20px 0 32px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }
  }

  .scout {
    position: relative;
    padding: 32px 0 41px;
    text-align: center;
    background: #F5F6F8;

    .leftArrow {
      position: absolute;
      top: 100px;
      left: 26px;
      width: 30px;
    }

    .rightArrow {
      position: absolute;
      right: 16px;
      bottom: 60px;
      width: 15px;
    }

    .content {
      img {
        display: block;
        width: 75%;
        margin: 0 auto;
      }

      p {
        padding: 62px 26px 41px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #000;
        text-align: left;
      }
    }
  }



  .questions {
    padding: 0 24px 43px;
    background: #2378E5;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 168px;
      height: 96px;
      margin: 0 auto 10px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #FFF;
      text-align: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }


  }

  .questionsItem {
    padding: 14px 19px;
    margin: 0 auto;
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #515151;
    background: #FFF;
    border-radius: 24px;

    .name {
      padding-bottom: 9px;
      margin-bottom: 9px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #2378E5;
      border-bottom: 1px solid #2378E5;
    }
  }
}

.answer {
  display: flex;

  .answerA {
    flex-shrink: 0;
  }

  .detail {
    flex: 1;
  }
}

@media (max-width: 370px) {
  .headerWrapper {
    padding: 0 10px 30px;
  }
}