.w1200 {
  display: flex;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 1140px;
  height: 100%;
  margin: 0 auto;

}

.overallItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  img {
    width: 180px;
    height: 180px;
  }

  p {
    padding: 26px 48px 26px 44px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    background: #F5F6F8;
    border-radius: 36px;

  }

  &:last-child {
    padding-bottom: 0;
  }

  .start {
    margin-right: 44px;
  }

  .num {
    margin-left: 60px;
  }

  .people {
    margin-right: 96px;
  }
}

.topHeader {
  height: 550px;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;

    span {
      position: relative;
      top: 4px;
      padding-left: 3px;
    }

    .talentTag {
      width: 140px;
      margin-left: 15px;
    }
  }
}


.talent {
  width: 100%;
  background-color: #fff;

  * {
    font-family: "Noto Sans JP", sans-serif !important;
  }

  img {
    object-fit: cover;
  }


  .talentHome {
    width: 570px;
  }


  .preview {
    font-size: 58px;
    font-style: normal;
    font-weight: 900;
    line-height: 77px;
    color: #FFF;
  }

  .received {
    padding-top: 8px;
    padding-bottom: 26px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
  }


  .w1200 {
    display: flex;
    align-items: center;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 72px;
    padding-bottom: 70px;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #111;

    svg {
      position: relative;
      top: 4px;
    }
  }

  .overall {
    padding-bottom: 93px;


  }

  .commonHeader {
    display: inline-block;
    margin: 0 auto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #0A0A0A;
    text-align: center;
  }

  .line {
    width: calc(100% - 20px);
    height: 15px;
    margin: 0 auto;
    background: linear-gradient(90deg, #088CFC 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  .kindWork {
    padding: 64px 0 102px;
    text-align: center;
    background: #F5F6F8;

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      width: 840px;
      padding-top: 55px;
      margin: 0 auto;
    }
  }

  .kindWorkItem {
    position: relative;
    justify-content: center;
    width: 260px;
    padding-bottom: 16px;
    overflow: hidden;
    background: #FFF;
    border: 5px solid #FFF;
    border-radius: 38px;

    .jobImgBox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 204px;
      border-radius: 34px;
    }

    img {
      width: 100%;
      height: 204px;
      margin-bottom: 12px;
      border-radius: 34px;
    }

    .tip {
      position: absolute;
      top: 160px;
      right: 0;
      left: 0;
      z-index: 12;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      color: #FFF;
      text-align: center;

    }


  }

  .info {
    display: flex;
    padding-left: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    text-align: left;

    i {
      position: relative;
      top: 10px;
      display: inline-block;
      flex-shrink: 0;
      width: 4px;
      height: 4px;
      margin-right: 12px;
      background-color: #000;
      border-radius: 50%;
    }
  }

  .header {
    display: inline-flex;
    align-items: center;
    color: #000;

    svg {
      margin-right: 5px;
    }
  }

  .features {
    padding: 64px 0 95px;
    margin: 0 auto;
    text-align: center;
    background: #2378E5;

    .commonHeader {
      margin-bottom: 82px;
    }

    .header {
      color: #FFCA30;
    }


    .line {
      background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 840px;
    max-width: 90%;
    padding: 39px 40px;
    margin: 0 auto;
    margin-bottom: 56px;
    text-align: left;
    background: #FFF;
    border-radius: 38px;

    &:last-child {
      margin-bottom: 0;
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 339px;
      height: 241px;
      background: #CFF8FF;
      border-radius: 29px;
      opacity: 0.94;
    }

    .num {
      position: absolute;
      top: -36px;
      left: 43px;
      width: 66px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #FFCA30;
    }



    h5 {
      padding-bottom: 13px;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
    }

    &:nth-child(3) {
      .num {
        right: 43px;
        left: unset;
      }

      h5 {
        padding-left: 10px;
      }
    }

    .info {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .login {
    padding: 64px 0 126px;
    text-align: center;
    background: #F5F6F8;

  }

  .loginBox {
    width: 840px;
    height: 547px;
    margin: 105px auto 0;
    overflow: hidden;
    background: #fff;
    border-radius: 28px;

    h2 {
      padding: 54px 0 43px 74px;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }
  }

  .imgBox {
    display: flex;
    justify-content: space-between;
    padding-left: 74px;
  }

  .talentLogin {
    width: 274px;
    height: 339px;
  }

  .loginHome {
    width: 460px;
  }

  .appBox {
    display: flex;
    gap: 24px;
    width: 840px;
    height: 504px;
    padding-top: 24px;
    margin: 0 auto;
  }

  .talentApp {
    position: relative;
    flex-shrink: 0;
    width: 408px;
    height: 504px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    background: #2378E5;
    border-radius: 43px;


    p {
      padding: 52px 0 64px 48px;
      text-align: left;
    }

    img {
      width: 293px;

    }
  }

  .position {
    width: 408px;
    height: 504px;
    background: #FFF;
    border-radius: 43px;

    img {
      height: 260px;
    }

    p {
      padding: 0 0 0 48px;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }
  }

  .method {
    position: relative;
    padding-top: 64px;
    padding-bottom: 150px;
    text-align: center;

    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 450px;
    }
  }

  .country {
    position: relative;
    z-index: 10;
    width: 840px;
    height: 423px;
    margin: 86px auto 40px;
    border-radius: 43px;

    .content {
      position: relative;
      z-index: 10;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    p {
      padding: 42px 0 0 48px;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: left;
    }

    img {
      position: absolute;
      top: -96px;
      right: -145px;
      z-index: -1;
      width: 333px;
    }
  }

  .userCenter {
    position: relative;
    z-index: 10;
    width: 840px;
    height: 530px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 43px;

    p {
      padding: 42px 48px 0 0;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      text-align: right;
    }
  }

  .scout {
    position: relative;
    padding: 64px 0 102px;
    text-align: center;
    background: #F5F6F8;

    .leftArrow {
      position: absolute;
      top: 116px;
      left: 10%;
      width: 105px;
    }

    .rightArrow {
      position: absolute;
      right: 10%;
      bottom: 45px;
      width: 45px;
    }

    .content {
      img {
        display: block;
        width: 660px;
        margin: 0 auto 106px;
      }

      p {
        padding: 125px 0 90px;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #000;
        text-align: center;
      }
    }
  }

  .loginBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    padding: 0 54px;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    cursor: pointer;
    background: #FFD14C;
    border-radius: 52px;
  }

  .questions {
    padding-bottom: 80px;
    background: #2378E5;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 378px;
      height: 220px;
      margin: 0 auto 10px;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #FFF;
      text-align: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }


  }

  .questionsItem {
    width: 840px;
    padding: 32px 50px 38px 38px;
    margin: 0 auto;
    margin-bottom: 24px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #515151;
    background: #FFF;
    border-radius: 38px;

    .name {
      padding-bottom: 20px;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #2378E5;
      border-bottom: 1px solid #2378E5;
    }
  }
}

.answer {
  display: flex;

  .answerA {
    flex-shrink: 0;
  }

  .detail {
    flex: 1;
  }
}

.headerWrapper {
  .loginBtn {
    height: 64px;
    font-size: 24px;
  }
}