@import 'styles/variable.scss';
@import '/styles/mixins.scss';

.jobTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 1px;
  color: #353535;
  padding-top: 50px;
  padding: 0 10px;

  @include sm {
    display: none;
  }
}

.searchMainBox {
  background-color: #fff;
  padding-bottom: 20px;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 28px;
  width: 100%;

  &_more {
    margin: 12px 0 42px 0;
    text-align: center;
    width: 100%;
  }

  .moreBtn {
    color: #1d2129;
    height: 44px;
    min-width: 146px;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    background: #ffffff;
    margin: 0 auto 10px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #c9cdd4;
    border-radius: 4px;
    padding: 0 10px;

    &:hover {
      color: #2378e5;
      border-color: #2378e5;
    }
  }
}

.job {
  &_detail {
    width: calc(50% - 8px);
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e5e6eb;
    background: #fff;
  }

  &_detail:hover {
    border: 1px solid #579bf5;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  }

  &_titleWrapper {
    display: flex;
    justify-content: space-between;
  }

  &_info {
    color: rgb(255, 255, 255);
    padding: 16px;
    display: block;

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #1d2129;
      width: 0;
      font-weight: 700;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: color 0.3s ease-in;
      letter-spacing: 0.15px;

      &:hover {
        color: #136fd3;
      }
    }

    .transBox {
      height: 34px;
      overflow-y: hidden;
      margin-top: 6px;
    }

    &_salary {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.02em;
      color: #2378e5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transform: translateY(0px);
      transition: all 0.4s ease 0s;
      height: 30px;
    }

    &_chat {
      display: inline-block;
      flex-direction: row;
      align-items: center;
      padding: 3px 10px;
      background: #136fd3;
      border: 0.5px solid #136fd3;
      border-radius: 4px;
      font-weight: 400;
      min-width: 76px;
      font-size: 14px;
      letter-spacing: 0.0075em;
      color: #ffffff;
      transform: translateY(30px);
      white-space: nowrap;
      transition: all 0.4s ease 0s;

      svg {
        position: relative;
        top: -2px;
        float: left;
        padding-right: 2px;
      }
    }
  }

  &_info:hover &_titleWrapper &_info_title {
    color: #136fd3;
  }

  &_info:hover {
    .job_info_salary {
      transform: translateY(-28px);
      opacity: 0;
    }

    .job_info_chat {
      transform: translateY(-28px);
    }
  }

  &_tags {
    // display: flex;
    padding: 0 0;

    div {
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.0075em;
      margin-right: 8px;
      color: #7d7d7d;
      margin-right: 10px;
      // max-width: 40%;
      margin-right: 10px;
      text-overflow: ellipsis;
      word-break: keep-all;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;

      &.jobLocation {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span {
        color: #e4e4e4;
        padding-left: 10px;
      }
    }
  }

  &_avatarWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &_box {
    display: flex;
    align-items: center;
    // max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;

    img {
      border: 1px solid #e5e6eb;
      border-radius: 5px;
    }
  }

  &_companyInfo {
    // background: linear-gradient(
    //   269.8deg,
    //   rgba(255, 235, 184, 0.2) 0.12%,
    //   rgba(242, 177, 202, 0.2) 43.36%,
    //   rgba(84, 142, 237, 0.2) 99.89%
    // );
    border-top: 1px solid var(--neutral-color-border, #f2f3f5);
    margin: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    display: block;

    &_name {
      margin-left: 10px;
      transition: color 0.3s ease-in;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.0075em;
      color: #4e5969;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_industry {
      color: #7d7d7d;
      float: right;
      font-size: 14px;
      white-space: nowrap;
      flex: 1;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        font-style: normal;
      }

      span {
        color: #e4e4e4;
        padding: 0 10px;
      }
    }
  }

  &_companyInfo:hover &_companyInfo_name {
    color: #136fd3;
  }
}

.popularJobsBox {
  background: #fcfcfc;

  .jobTitle {
    padding: 40px 0 20px 0;
    line-height: 55px;
    margin: 0;
  }

  .backBtn {
    position: fixed;
    bottom: 0;
    right: calc(50vw - 660px);
    width: 60px;
    height: 50px;
    border-radius: 8px 8px 0px 0px;
    background: #3a90ff;
    // display: flex;
    display: none; // 隐藏掉这个backtop按钮
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);

    &:hover {
      background: #121212;

      svg {
        transition: 0.2s;
        transform: scale(1.1);
      }
    }
  }
}

.webTab {
  position: relative;
  @extend .GlobalNextAppContainer;

  :global {
    .MuiTabs-root {
      border-bottom: 0;
    }

    .MuiTabs-root .MuiTabs-indicatorSpan {
      background-color: #1d2129;
    }

    .MuiButtonBase-root {
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 3px;
        bottom: 0px;
        left: 0;
        background-color: #1d2129;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }

  .preference {
    position: absolute;
    top: 14px;
    right: 0;
    font-size: 16px;

    &Box {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .vipAvatar {
      position: relative;
      border-radius: 50%;
      display: inline-block;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      box-sizing: border-box;
      border: 2px solid #ffc248;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .link {
      color: #136fd3;
      text-decoration: underline;
    }

    .link2 {
      color: #136fd3;
    }
  }
}

.mobileTab {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
  // padding-left: 10px;
  // padding-right: 10px;
}

@include sm {
  .title {
    text-align: left;
    padding-top: 48px;
  }
}

@include sm {
  .webTab {
    display: none;
  }

  .mobileTab {
    display: none;
  }

  .remoteHome {
    .webTab {
      display: block;
      padding: 0 16px;

      .preference {
        position: relative;
        padding: 10px 10px 0 0px;
      }
    }

    .webTab {
      display: block;
    }

    .jobTitle {
      display: block;
      text-align: left;
      font-size: 21px;
      padding: 0px 0 0px 16px;
    }
  }
}

.mobileHome {
  display: none;

  p {
    margin-bottom: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 1px;
    color: #121212;
    padding: 24px 14px 0 14px;
    margin: 0;
  }

  .hiring {
    overflow: scroll;
    width: 100%;
    height: auto;
    padding: 0 14px;

    .itemBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      width: 100%;
      min-height: 95px;
      display: block;
      background: #ffffff;
      margin: 20px 0 0 0;
      display: flex;
      padding: 12px;
      border-bottom: 1px solid #e5e6eb;
      background: #fff;
      position: relative;

      .arowForward {
        position: absolute;
        right: 20px;
        top: 32px;
      }

      img {
        box-sizing: border-box;
        width: 48px;
        height: 48px;
        border: 1px solid #e5e6eb;
        border-radius: 7px;
        margin-right: 8px;
      }

      .info {
        flex: 1;
        width: 0;
        margin-right: 24px;

        .name {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1px;
          color: #121212;
          text-overflow: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0;
        }

        .companyInfo {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.0075em;
          color: #7d7d7d;
          text-overflow: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          padding: 4px 0 6px 0;
        }

        .num {
          font-size: 15px;
          line-height: 18px;
          color: #136fd3;
          margin: 0;
          padding-top: 10px;
        }
      }
    }
  }

  .moreBtnActive {
    color: #2378e5;
    height: 44px;
    width: 146px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    background: #ffffff;
    margin: 20px auto 0;
    display: block;
    cursor: pointer;
    text-align: center;
    border: 1px solid #2378e5;
  }

  .jobs {
    padding: 44px 14px 40px 14px;

    h2 {
      padding-left: 0;
      padding-top: 0;
    }

    .tips {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #707070;
      margin: 0;
      line-height: 30px;
      margin-bottom: 16px;
    }

    .preference {
      color: #136fd3;
    }

    .started {
      line-height: 30px;
      text-align: center;
      width: 91px;
      height: 30px;
      background: #136fd3;
      border-radius: 8px;
      float: right;
      cursor: pointer;
      font-size: 12px;
      color: #ffffff;
    }

    .jobCard {
      background: #ffffff;
      padding: 16px;
      margin-bottom: 8px;
      border-radius: 8px;
      border: 1px solid #f2f3f5;
      background: #fff;

      img {
        vertical-align: middle;
      }

      .name {
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        color: #1d2129;

        .jobName {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 20px;
          color: #1d2129;
          margin-bottom: 4px;
          font-style: normal;
          line-height: normal;
        }

        .salary {
          width: fit-content;
          text-align: right;
          font-size: 20px;
          color: #2378e5;
          font-weight: 400;
          padding: 6px 0;
        }
      }

      .company {
        font-size: 14px;
        letter-spacing: 1px;
        color: #353535;
        padding: 3px 0 4px 0;
      }

      .tag {
        font-weight: 400;
        font-size: 12px;
        color: #4e5969;
        padding: 0 8px;
        margin-right: 8px;
        background: #f7f8fa;
        border-radius: 6px;
        display: inline-block;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .contact {
        margin: 16px 0 0;
        padding-top: 10px;
        font-size: 12px;
        color: #4e5969;
        line-height: 26px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #f2f3f5;

        .location {
          float: right;
          color: #86909c;
          width: 20vw;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hrFullInfo {
          width: 65vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .load {
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #999;
    margin-top: 20px;
    text-align: center;
  }

  .loadMore {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 30px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #2378e5;
    color: #2378e5;
    font-size: 14px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .startedContainer {
    position: fixed;
    bottom: 0;
    height: 40px;
    width: 100%;
    left: 0;
    line-height: 40px;
    background: #136fd3;
    z-index: 10;

    .close {
      float: left;
      margin: 8px;
      cursor: pointer;
    }

    .tips {
      line-height: 40px;
      color: #fff;
      font-size: 14px;

      span {
        color: #136fd3;
        background: #fff;
        font-weight: 700;
        margin: 5px 10px 0 0;
      }
    }
  }
}

.avator {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  display: inline-block;
  top: -2px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #32a071;
    border: 2px solid #fff;
  }
}

.avator2 {
  &::after {
    background: #ccc;
  }
}

@media (max-width: 750px) {
  .mobileHome {
    display: block;
    width: 100%;
  }
}

.recommendContainer {
  @extend .GlobalNextAppContainer;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  // margin-bottom: 20px;

  @include msd {
    display: none;
  }

  .mainFunction,
  .recommend {
    width: calc(50% - 5px);
  }

  .mainFunction {
    // background-color: bisque;
    height: 100%;
  }

  .recommend {
    display: flex;
    justify-content: space-between;

    &_container {
      // background-color: rosybrown;
      // animation: loading 2s ease infinite;
      background-size: 300% 100%;
      background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
      background-position: 100% 50%;
      width: calc(50% - 5px);
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
    }

    @include xl {
      width: 610px;
    }
  }
}

@keyframes loading {
  0% {
    background-size: 300% 100%;
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 100% 50%;
  }

  100% {
    background-size: 300% 100%;
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 0 50%;
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(-80%);
  }
}

.functionFilter {
  width: 552px;
  height: 250px;
  position: relative;
  padding-right: 10px;

  @include sm {
    display: none;
  }

  .mainItems {
    width: 100%;
    height: 100%;
    // border-radius: 20px;
    overflow: hidden;
    position: relative;
    background: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid #e4e4e4;

    // padding: 10px 10px;
    .mainItem {
      // width: 100%;
      height: 41px;
      padding: 8px 10px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      position: relative;

      &.isHover {
        // border: 0.5px solid #f0f0f0;
        // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        // border-radius: 10px;
        border-radius: 4px;
        background: #f7f8fa;

        .mainTitle {
          color: #136fd3;
        }
      }

      &:hover {
        // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);

        .mainTitle {
          color: #136fd3;
        }
      }

      .mainTitle {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        align-items: center;

        svg {
          width: 12px;
        }

        .subContainer {
          display: flex;
          flex-direction: row;
          // max-width: 358px;
          justify-content: flex-start;
          flex: 1;

          .mainTitleSub {
            // flex: 1;
            display: block;
            font-family: 'Product Sans';
            font-style: normal;
            width: fit-content;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.0075em;
            color: #707070;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: min-content;
            // max-width: 110px;
            margin-left: 25px;
            // max-width: 170px;
            // flex-grow: 1;
            cursor: pointer;

            &:hover {
              color: #136fd3;
            }
          }
        }

        &First {
          font-family: 'Product Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;
          color: #353535;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-shrink: 0;
        }
      }

      .more {
        font-size: 12px;
        // position: absolute;
        // right: 10px;
        // top: 16px;
        color: #707070;
        flex-shrink: 0;
      }
    }

    .pagination {
      // position: absolute;
      // left: 20px;
      // bottom: 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      margin-top: 8px;

      label {
        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.0075em;
        color: #353535;
        // margin-right: 50px;
        width: 40px;
        // position: absolute;
        // left: 5px;
        // bottom: 4px;

        span {
          color: #b8b8b8;
        }
      }

      button {
        background: none;
        border: none;
        width: 20px;
        height: 20px;
        // position: relative;
        border-radius: 50%;
        // left: -36px;
        cursor: pointer;
        font-size: 12px;
        background: #e7f1fb;
        margin-left: 5px;
        text-align: center;
        line-height: 20px;
        color: #136fd3;

        &:hover {
          background: #136fd3;
          color: #fff;

          .icon {
            color: #fff;
          }
        }

        &:disabled {
          background: #f0f0f0;
          color: #707070;
        }

        .icon {
          font-size: 12px;
          margin: 0 auto;
          display: block;
          color: #707070;
        }
      }
    }
  }

  .sectionContainer {
    position: absolute;
    right: -110%;
    top: 0px;
    background: #fff;
    width: 110%;
    height: 100%;
    border-radius: 8px;
    overflow-y: scroll;
    padding: 18px 18px 18px 0px;
    flex-direction: column;
    overflow: auto;
    // border: 1px solid #e4e4e4;

    &::-webkit-scrollbar {
      width: 5px;
      right: 10px;
    }

    // &::-webkit-scrollbar-track {
    //     background: #fff;
    //     border-radius: 10px;
    //     margin-right: 10px;
    // }

    &::-webkit-scrollbar-thumb {
      background: $white-2;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $white-2;
    }

    .sectionItems {
      display: flex;
      flex-direction: column;
      height: inherit;
      width: 100%;
      height: auto;

      .sectionName {
        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        align-items: flex-start;
        text-align: start;
        letter-spacing: 0.0075em;
        color: #707070;
        height: 18px;
        margin-left: 30px;
      }

      .subItems {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-flow: wrap;
        margin-top: 15px;
        margin-bottom: 18px;
        padding-left: 14px;

        .subItem {
          width: 23%;
          margin-left: 2%;
          overflow: hidden;
          height: 30px;

          .linkText {
            font-family: 'Product Sans';
            text-overflow: ellipsis;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-left: 5px;
            letter-spacing: 0.0075em;
            color: #353535;
            text-overflow: ellipsis;
            width: 100%;
            height: 100%;
            overflow: hidden;
            line-height: 100%;
            white-space: nowrap;
          }

          &:hover {
            .linkText {
              color: #136fd3;
              text-overflow: none;
              width: fit-content;
              // animation: 3s wordsLoop linear infinite;
            }
          }
        }
      }
    }
  }
}

.searchMain {
  width: 100%;
  height: 273px;
  position: relative;
  background-image: linear-gradient(272deg, #26bcf7 0%, #367bff 100%);

  @include sm {
    height: 158px;
  }
}

.searchMainContainer {
  height: 100%;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  max-width: 1136px;
  margin: 0 auto;

  @include sm {
    max-width: 100%;
  }
}

.searchBackground {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.searchArea {
  position: relative;
  background: none;
  top: -65px;
  left: 0;
  width: 100%;
  z-index: 8;

  @include sm {
    top: 15px;
    background: #fff;
    // padding: 10px 0px;
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 100%;
    width: 832px;
    position: relative;
    @extend .GlobalNextAppContainer;

    .searchWrapper {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
      padding: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
      border: 1px solid #2378e5;

      &:hover {
        border-color: #2378e5;
      }

      @include sm {
        display: block;
        width: 100%;
        background: transparent !important;
        padding: 0;
        border: none;
        margin-left: 0;

        &:hover {
          border-radius: 0px;
          border-color: transparent;
        }
      }
    }

    .location {
      :global {
        .MuiInputLabel-root {
          display: none;
        }

        .MuiOutlinedInput-root {
          height: 60px;
          overflow: hidden;
          border-radius: 10px;
          background: #fff;
        }

        .MuiOutlinedInput-input {
          padding-left: 0;
        }
      }

      width: 158px;
      height: 60px;
      // overflow: hidden;
      // border-radius: 8px;
      // border: 1px solid #e5e6eb;
      border-radius: 8px 0 0 8px;
      background: #fff;
      // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      // @include sm {
      //   width: 140px;
      // }
    }
  }

  @include sm {
    width: 100%;
    overflow-x: hidden;
    padding-top: 4px;
  }

  // margin-left: 10%;
  .location {
    width: 183px;
    height: 34px;

    @include sm {
      width: 140px;
    }
  }

  .searchSpread {
    height: 48px;
    width: 1px;
    margin-left: 10px;
    background-color: #e4e4e4;

    @include sm {
      display: none;
    }
  }

  .search {
    margin: 0px;
    background-color: #fff;
    border-radius: 8px 0 0 8px;
    height: 60px;
    width: 670px;

    :global {
      .MuiAutocomplete-root {
        background-color: ffffff;
      }

      input::placeholder {
        color: #86909c;
        opacity: 1;
      }

      .MuiAutocomplete-input {
        font-size: 16px;
      }

      .MuiOutlinedInput-root {
        height: 60px;
      }

      .MuiInputLabel-root {
        top: 12px;
      }

      .MuiFormLabel-filled,
      .Mui-focused {
        top: 0;
        border-radius: unset;
      }

      .MuiOutlinedInput-notchedOutline {
        // border-radius: 8px 0 0 8px;
        border-width: 0 !important;
      }
    }

    @include sm {
      :global {
        .MuiInputLabel-root {
          top: 3px;
        }
      }
    }
  }

  .searchButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    color: #fff;
    width: 126px;
    height: 60px;
    font-size: 18px;
    background: #2378e5;
    border-radius: 0 7px 7px 0;
    font-weight: 700;

    // flex: none;
    // order: 2;
    // flex-grow: 0;
    &:hover {
      background: rgb(24, 84, 163);
    }

    @include sm {
      border-radius: 0 2px 2px 0;
      width: 104px;
    }

    // &:hover {
    //   color: #136fd3;
    // }

    &:disabled {
      color: #fff;
    }
  }
}

.searchAreaRemote {
  top: 45px;

  .box {
    .searchWrapper {
      margin-left: 0;
      width: 100%;
    }

    .search {
      width: 660px;
      margin: 0;
    }
  }
}

.popularJobs {
  background: none;
  margin-top: -20px;

  @include sm {
    background: #fff;
    margin-top: 26px;
  }

  .popularBox {
    @extend .GlobalNextAppContainer;
    display: flex;
    flex-direction: row;
    // padding: 26px 0 23px 32px;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;

    @include sm {
      padding: 18px 16px 27px 16px;
      display: block;
      background: #fff;
    }
  }

  // @include sm {
  //   flex-direction: column;
  //   height: fit-content;
  //   flex-wrap: wrap;
  //   align-items: flex-start;
  //   width: 100%;
  //   margin-left: 0px;
  //   margin-top: 48px;
  // }

  label {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;
    width: fit-content;
    flex-shrink: 0;
    color: #86909c;

    @include sm {
      font-family: 'Product Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 27px;
      letter-spacing: 0.02em;
      color: #353535;
      justify-content: flex-start;
    }
  }

  .tagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0px;

    &:active,
    &:hover {
      color: #2378e5;
    }

    @include sm {
      flex-wrap: wrap;
      height: fit-content;
      width: 100%;
    }

    .tag {
      flex-shrink: 0;
      width: fit-content;
      height: 36px;
      font-family: 'Product Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.0075em;
      margin: 0px 0 0 15px;
      padding: 0px 10px;
      width: 152px;
      background: #f7f8fa;
      border-radius: 4px;

      // border: 1px solid #e5e6eb;
      color: #4e5969;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include sm {
        width: calc(50% - 8px);
        margin: 16px 16px 0 0;

        &:nth-of-type(even) {
          margin-right: 0;
        }
      }

      &:hover {
        background-color: #f2f3f5;
      }
    }
  }

  // min-width: 940px;
}

@keyframes showTrans {
  0% {
    transform: translate(0, -25px);
  }

  // 35% {
  //   transform: translate(0, -0.497px);
  // }

  // 53% {
  //   transform: translate(0, -6.249px);
  // }

  // 71% {
  //   transform: translate(0, -0.621px);
  // }

  // 82% {
  //   transform: translate(0, -1.488px);
  // }

  // 88% {
  //   transform: translate(0, -0.234px);
  // }

  // 94% {
  //   transform: translate(0, -0.385px);
  // }

  100% {
    transform: translate(0, 0);
  }
}

.searchAreaFix {
  height: 110px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 3px 3px 18px 5px #e8e8e8d1;
  z-index: 100;
  transition: 0.3s;
  animation: showTrans 0.25s ease-in-out both;

  .box {
    min-width: 1136px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;

    .searchWrapper {
      padding: 0;
      margin: 0;
      // .search {
      //   width: 810px;
      // }
    }
  }

  :global {
    .MuiOutlinedInput-notchedOutline {
      border-width: 0.5px !important;
    }

    .MuiOutlinedInput-root {
      overflow: unset;
    }
  }

  .shadeheader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background: rgba(0, 0, 0, 0.3);
  }

  .download {
    margin-left: 20px;
    width: fit-content;
    font-size: 14px;
    color: #136fd3;
    cursor: pointer;
    position: relative;
    word-break: keep-all;
    border-radius: 8px;
    border: 1px solid #2378e5;
    min-width: 204px;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @include msd {
      display: none;
    }

    .icon {
      float: left;
      font-size: 32px;
      line-height: 18px;
      letter-spacing: 1px;
    }

    > p {
      color: #2378e5;
      font-size: 16px;
      padding-left: 9px;
      margin-bottom: 0;
      font-weight: 700;
      line-height: 18px;

      span {
        font-size: 12px;
        color: #515151;
        font-weight: normal;
      }
    }

    &:hover {
      border: 1px solid #579bf5;

      .icon {
        svg,
        path {
          fill: #579bf5;
        }
      }

      > p {
        color: #579bf5;
      }

      .popver {
        transition: transform 0.4s;
        transform: translateY(0%);
      }
    }
  }

  .popver {
    position: fixed;
    background: #ffffff;
    // z-index: 100;
    // box-shadow: 0px 0px 14px #bcbcbc;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: 0.4s;
    z-index: 2000;
    padding-top: 110px;
    top: 0px;
    transform: translateY(-100%);

    .popverContainer {
      background: #2378e5;
    }

    .popverMain {
      @extend .GlobalNextAppContainer;
      display: flex;
      padding: 0 124px;
      position: relative;
      height: 100%;
      height: 296px;

      .info {
        width: 490px;
        margin-right: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        h5 {
          color: var(--main-color-white-white-ffffff, #fff);
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: 0px;
          margin: 0;
          // padding: 50px 0 20px;
        }

        .getAppTips {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.12px;
          margin: 0;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding-bottom: 30px;
        }
      }

      .code {
        width: 338px;
        position: relative;

        .qrcode {
          width: 148px;
          height: 148px;
          background-color: #fff;
          border-radius: 8px;
          position: absolute;
          left: 92px;
          top: 115px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .homeBg {
          position: absolute;
          left: 0;
          top: 37px;
        }
      }
    }
  }
}

.searchAreaFixRemote {
  .box {
    .searchWrapper {
      .search {
        width: 810px;
      }
    }
  }
}

.searchshade {
  position: fixed;
  z-index: 101;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}

.errorMain {
  text-align: center;
  margin: 100px 0;

  h2 {
    margin-bottom: 20px;
  }

  &_loadingLogo {
    margin-bottom: 20px;
  }
}

.container {
  // max-width: 1136px;
  width: 100%;
  margin: 0 auto;
  // padding-left: 10px;
  // padding-right: 10px;
  background-color: #fff;

  @media (max-width: 799px) {
    max-width: 688px;
  }
}

.layoutHeader {
  background: blue;
  height: 53px;
  width: 100vw;
}

.loading {
  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $primaryBlue;
  }

  &Logo {
    margin-bottom: 16px;
  }

  &Indicator {
    width: 200px;
  }
}

// @extend .GlobalNextAppContainer;

.title {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 26px 0 28px 0;
  flex-direction: column;
  // background: #f0f4f7;

  // span {
  //   color: #2378e5;
  // }
}

.titleText {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin-top: 40px;
  z-index: 8;
  margin-bottom: 0;
  color: #fff;

  @include sm {
    font-size: 32px;
    margin-top: 0;
    padding: 0 10px;
    text-align: center;
    // padding-top: 58px;
  }
}

.subTitle {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  margin: 0;

  @include sm {
    font-size: 28px;
  }
}

.companies {
  padding-bottom: 72px;
  @extend .GlobalNextAppContainer;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1px;
    color: #353535;
    text-align: center;
    padding: 40px 0;
    margin: 0;
  }

  .companyContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    background: #ffffff;
    min-height: 120px;
    width: calc(50% - 8px);
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid#E5E6EB;
    background: #fcfcfc;

    &:nth-of-type(even) {
      margin-right: 0;
    }

    .header {
      // height: 110px;
      cursor: pointer;
      display: block;
      border-bottom: 1px solid #e5e6eb;
      background-color: #fff;
      padding: 0 20px 20px 20px;

      .img {
        width: 48px;
        height: 48px;
        float: left;
        margin: 20px 11px 0 0;
        border-radius: 5px;
        border: 1px solid #e5e6eb;
      }

      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #121212;
        padding: 20px 0 4px 0;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 1px;
        color: #707070;
        padding-top: 2px;

        span {
          color: #e5e6eb;
          padding: 0 10px;
        }
      }

      &:hover h5 {
        color: #136fd3;
      }
    }

    .list {
      margin: 0 20px;
      padding-bottom: 20px;
      cursor: pointer;
      display: block;
      border-bottom: 1px solid #f6f6f6;
      padding: 20px 0;

      .jobType {
        letter-spacing: 1px;
        display: flex;
        padding-bottom: 10px;
        position: relative;

        p {
          flex: 1;
          width: 0;
          color: #121212;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
          font-size: 20px;
          margin: 0;
          transition: all 0.4s ease 0s;
        }
      }

      .transBox {
        height: 34px;
        overflow-y: hidden;
      }

      .salary {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #2378e5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transform: translateY(0px);
        transition: all 0.4s ease 0s;
        height: 30px;
        margin-bottom: 4px;
      }

      .chat {
        display: inline-block;
        flex-direction: row;
        align-items: center;
        padding: 3px 10px;
        background: #136fd3;
        border: 0.5px solid #136fd3;
        border-radius: 4px;
        font-weight: 400;
        min-width: 76px;
        text-align: right;
        font-size: 14px;
        letter-spacing: 0.0075em;
        color: #ffffff;
        transform: translateY(24px);
        white-space: nowrap;
        transition: all 0.4s ease 0s;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

        svg {
          position: relative;
          top: -2px;
          float: left;
          padding-right: 2px;
        }
      }

      .tag {
        font-weight: 400;
        font-size: 16px;
        color: #7d7d7d;
        display: inline-block;

        i {
          font-style: normal;
          color: #e4e4e4;
          padding: 10px;
        }
      }

      &:hover {
        .jobType p {
          transition: all 0.4s ease 0s;
          color: #136fd3;
        }

        .chat {
          transform: translateY(-34px);
        }

        .salary {
          transform: translateY(-34px);
          opacity: 0;
        }
      }
    }

    .linkAddress {
      color: #4e5969;
      font-weight: 400;
      font-size: 15px;
      text-align: center;
      margin: 30px 0;
      display: block;

      &:hover {
        color: #136fd3;
      }
    }
  }

  .moreBtn {
    min-width: 146px;
    color: #1d2129;
    height: 44px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    background: #ffffff;
    margin: 26px auto 0px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #c9cdd4;
    padding: 0 10px;

    &:hover {
      color: #2378e5;
      border-color: #2378e5;
    }
  }
}

.remoteHome {
  .title {
    padding-top: 34px;
  }

  .popularJobs {
    padding-top: 96px;
  }

  .tagContainer {
    .tag {
      min-width: 150px;
    }
  }

  .searchMain {
    background-color: #3488f0;
    background-image: none;
  }

  .searchMainContainer {
    max-width: 1920px;
  }

  .titleText {
    @include sm {
      padding: 0 8px;
      margin-top: 8px;
      text-align: center;
    }
  }
}

.guide {
  @extend .GlobalNextAppContainer;
  padding-bottom: 40px;

  h2 {
    color: #353535;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
    letter-spacing: 0.24px;
    padding: 30px 0 28px;
    text-align: center;
  }

  .item {
    padding: 20px 0;
    border-bottom: 1px solid #e4e4e4;

    a {
      display: flex;
    }

    img {
      border-radius: 4px;
    }

    .content {
      padding: 0 0 0 15px;

      h5 {
        color: #121212;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.18px;
        margin: 0;

        &:hover {
          color: #136fd3;
        }
      }

      p {
        color: #515151;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-top: 14px;
        margin: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .viewMore {
    width: 146px;
    color: #1d2129;
    height: 44px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    background: #ffffff;
    margin: 26px auto 0px;
    display: block;
    cursor: pointer;
    border: 1px solid #c9cdd4;

    &:hover {
      color: #2378e5;
      border-color: #2378e5;
    }
  }
}

@media (max-width: 750px) {
  .title {
    font-size: 30px;
    height: 100%;
  }

  .search {
    width: auto;
    margin: 0;
  }

  .popularBox {
    display: block;
    padding: 158px 16px 27px 16px;
  }

  .searchArea {
    padding: 0 14px;
    bottom: -150px;

    .search {
      flex: 1;

      :global {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }

    .box {
      border-radius: 4px;
      background: #fafafa;
      margin: 0;
      padding: 0;
      width: 100%;
      min-width: 100%;

      .location {
        width: 100%;
        border-radius: 4px 0 0 4px;
        border-bottom-width: 0;
        border-top-width: 0;
        border-left-width: 0;
        border-radius: 4px;
        border: 1px solid #e4e4e4;
        background: #fff;
        padding: 0 12px;

        :global {
          .MuiOutlinedInput-root {
            width: 100%;
          }
        }
      }

      .searchBox {
        flex: 1;
        margin-top: 14px;
        border-radius: 4px;
        border: 1px solid #2378e5;
      }
    }

    .searchAreaFix {
      position: initial;
      height: auto;
    }
  }

  .companies {
    display: none;
  }

  .remoteHome {
    .searchArea {
      bottom: unset;
      position: absolute;
      top: 188px;

      .box {
        background-color: #fff;

        .searchBox {
          margin-top: 0;
        }
      }
    }

    .popularBox {
      padding-top: 0px;
    }

    .popularJobsBox {
      padding: 0;
    }

    .companies {
      display: block;

      h2 {
        font-size: 21px;
        text-align: left;
        padding: 0px 0 20px 0;
      }
    }

    .tabContainer_more {
      margin: 16px 0 22px;

      .moreBtn {
        border-color: #2378e5;
        color: #2378e5;
      }
    }
  }

  .guide {
    padding: 30px 16px 0;

    h2 {
      text-align: left;
      font-size: 21px;
      padding: 0;
      margin: 0;
    }

    .item {
      .content {
        padding: 0;
      }

      a {
        display: block;

        img {
          width: 100%;
          height: auto;
          padding-bottom: 20px;
        }
      }
    }
  }
}

.popularJobsBoxRemote {
  background-color: #fff;
  padding-top: 30px;
}

.companiesRemote {
  background: #f7f8fa;

  .companies {
    .companyContainer {
      display: block;
    }
  }

  @include sm {
    background: #fff;

    .companies {
      padding-bottom: 40px;
      padding: 0 16px;
    }

    .moreBtn {
      margin: 10px auto 0;
      border-color: #2378e5;
      color: #2378e5;
    }
  }
}

.pushRemoteModalBg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
}

.pushRemoteModal {
  position: fixed;
  left: 50%;
  z-index: 100;
  top: 50%;
  width: 550px;
  height: 520px;
  transform: translate(-50%, -50%);
  background-color: #fff;

  @include sm {
    width: 302px;
    border-radius: 14px;
    overflow: hidden;
    height: auto;
    min-height: 350px;
    padding-bottom: 20px;
  }

  .thumb {
    width: 100%;
    height: 292px;
    position: relative;
    background-color: #0870fa;

    img {
      width: 100%;
      height: 100%;
    }

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 10;
      display: inline-block;
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include sm {
        right: 10px;
        top: 10px;
      }
    }

    @include sm {
      height: 160px;
    }
  }

  p {
    padding: 40px 76px 40px 76px;
    color: #121212;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin: 0;

    @include sm {
      padding: 20px;
      color: #121212;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .button {
    display: flex;
    padding: 0 65px;
    justify-content: space-between;
    flex-direction: row-reverse;

    button {
      width: 200px;
    }

    @include sm {
      padding: 0 20px;
      display: block;

      button {
        width: 260px;
      }
    }
  }
}
