.pagination {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .paginationItemBase {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .paginationItemNumber {
    border: 1px solid #e4e4e4; // var(--neutral-color-line-03-e-4-e-4-e-4,);

    &[data-checked="true"] {
      color: #fff;
      background-color: #2378e5;
      border-color: #2378e5;
    }

    &[data-checked="false"]:hover {
      color: #2378e5;
      border: 1px solid #2378e5; // var(--brand-color-052378-e-5, #);
    }
  }

  .actionBtn {
    padding: 4px;
    cursor: pointer;

    &.prePageBtn {
      svg {
        transform: rotate(180deg);
      }
    }

    border: none;

    &[data-disabled="false"]:hover {
      background-color: #e8e8ea;
    }

    &[data-disabled="true"] {
      // color: #fff;
      // background-color: #555;
      cursor: not-allowed;

      svg path {
        fill: #b8b8b8;
      }
    }
  }
}
